.profile-picture {
    width: 200px;
    height: 200px;
  margin-top: -100px;
 margin-left:  10px;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  }
  
  .profile-content {
    padding: 20px;
  }
  .profile-card{
    width: 100%;
    background: #fff;
    color: #333;
  }
  .cover-picture{
    display: block;
    width: 100%;
    height: 200px;
  }
  .profile-summary{
    box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1)
  }

  .container {
    margin-top: 20px;
  }
  
  .row {
    display: flex;
  }
  
  .col-lg-4 {
    flex: 0 0 30%;
    max-width: 30%;
  }
  
  .col-lg-8 {
    flex: 0 0 70%;
    max-width: 70%;
  }
  
  .conversation-list {
    box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
    padding: 10px;
    height: calc(100vh - 70px);
    overflow-y: auto;
  }
  
  .conversation-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .conversation-item.active {
    background-color: #1c2957; /* Change to LinkedIn blue color */
    color: #fff;
  }
  
  .chatbox {
    padding: 20px;
    box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  }
  
  .chat {
    display: none;
  }
  
  .chat.active {
    display: block;
  }
  
  .message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .user-message {
    background-color: #e7f0fe;
    text-align: right;
  }
  
  .bot-message {
    background-color: #f2f2f2;
  }
  
  .message-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .message-input input {
    flex: 1;
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .message-input button {
    padding: 8px 20px;
  }
  .tab-buttons {
   
    border: none !important;
}

.tab-buttons button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 8px 16px;
    border: none !important;
    margin-right: 10px;
}

.tab-buttons button.active {
  position: relative;
  z-index: 1;
  overflow: hidden;
  vertical-align: middle;
  font-family: roboto;
  background-color: #1c2957;
  color: #fff;
  border: none !important;
}

